@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

@layer utilities {
    /* Custom utility to hide number input buttons */
    .input-otp::-webkit-inner-spin-button,
    .input-otp::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .input-otp {
        -moz-appearance: textfield; /* Firefox */
    }
}

html, body, #__next {
	@apply h-full !p-0
}

#__next > div:first-of-type {
	@apply h-full
}

.auth-swiper .swiper-pagination-bullets {
	@apply block !left-auto !right-0 !w-auto;
}

.auth-swiper .swiper-pagination-bullet {
	@apply opacity-100 border border-white !outline-none ease-in-out duration-200 !bg-transparent;
}

.auth-swiper  .swiper-pagination-bullet-active {
	@apply !bg-white
}

.tw-btn-solid {
    @apply py-3 px-4 rounded-[8px] bg-[#34484F] border-[#34484F] font-medium capitalize hover:border-[#405861] hover:bg-[#405861] text-white text-center ease-out duration-300
}

.tw-btn-outline {
    @apply py-3 cursor-pointer px-4 rounded-[8px] border font-medium capitalize hover:bg-[#405861] text-[#405861] hover:text-white text-center ease-out duration-300
}

.tw-btn-sm {
    @apply text-sm rounded-[4px] font-normal bg-white py-2 hover:bg-white hover:text-[#34484F]
}

.tw-btn-outline-dangor {
    @apply text-red-600 hover:bg-red-600 hover:text-white hover:border-red-600
}

.tw-btn-solid[disabled] {
    @apply opacity-60 select-none hover:bg-[#34484F]
}

.tw-btn-outline[disabled] {
    @apply opacity-60 select-none hover:bg-white text-[#405861]
}

.pagination {
    @apply gap-3
}

/* Apply base styles to all except the first child, break-me, and last child */

.pagination li a {
    @apply block text-center bg-white hover:bg-[#567580] hover:!text-white ease-out duration-200;
}

.pagination li:not(:first-child):not(.break-me):not(:last-child) a {
    @apply block rounded-full py-2 w-14 border;
}

.pagination .previous {
    @apply ms-0 me-auto
}

.pagination .next {
    @apply me-0 ms-auto
}

.pagination .previous a {
    @apply py-2 hover:!bg-[#34484F]
}

.pagination .next a {
    @apply py-2 hover:!bg-[#34484F]
}

/* Override styles for the first child */
.pagination li:first-child a {
    @apply rounded-[8px] bg-white border block px-6 w-auto;
}

/* Override styles for the break-me class */
.pagination li.break-me a {
    @apply block;
}

/* Override styles for the last child */
.pagination li:last-child a {
    @apply rounded-[8px] bg-white border block px-6 w-auto;
}

.pagination .pagination__link--active a {
    @apply !bg-[#34484F] border-0
}

.pagination__link--disabled a {
    @apply opacity-50
}

.liked-property {
    @apply text-red-500;
}

.date-piker input {
    @apply !outline-none shadow-none focus:ring-0 text-[#0D0D12]
}

.date-piker button {
    @apply text-[#0D0D12]
}

.date-piker .dark\:bg-white\/10{
    @apply bg-[#34484F] text-white
}

.date-piker .bg-cyan-500 {
    @apply bg-[#34484F]
}

.date-piker .dark\:text-cyan-400 {
    @apply text-[#0D0D12]
}

.drag-zone {
    background-image: repeating-linear-gradient(30deg, #a4acb9, #a4acb9 14px, transparent 14px, transparent 30px, #a4acb9 30px), repeating-linear-gradient(120deg, #a4acb9, #a4acb9 14px, transparent 14px, transparent 30px, #a4acb9 30px), repeating-linear-gradient(210deg, #a4acb9, #a4acb9 14px, transparent 14px, transparent 30px, #a4acb9 30px), repeating-linear-gradient(300deg, #a4acb9, #a4acb9 14px, transparent 14px, transparent 30px, #a4acb9 30px);
    background-size: 1px 100%, 100% 1px, 1px 100% , 100% 1px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
}

.fr-wrapper.show-placeholder {
    @apply rounded-br-[8px] rounded-bl-[8px] 
}
.fr-second-toolbar {
    @apply hidden
}

input[disabled]  {
    @apply opacity-70
}

.PhoneInputInput[disabled]  {
    @apply bg-transparent
}

.read-only .PhoneInputCountry {
    @apply hidden
}

.map-card .swiper-button-prev,
.map-card .swiper-button-next {
    @apply bg-white w-8 h-8 rounded-full border p-0
}

.map-card .swiper-button-prev:after,
.map-card .swiper-button-next:after {
    @apply text-sm text-slate-800
}


.tag-icon-class svg {
    fill: #ffffff;
}
